/* eslint-disable no-loop-func */
/* eslint-disable no-console */
import Base from '../../_config/base';

class ProjectList extends Base {
  constructor(el) {
    super(el);
    const filterBtns = el.querySelectorAll('.projectList__filter_item');
    const filter = el.querySelector('.projectList__filter');
    const projects = el.querySelectorAll('.projectList__image');
    const column1Container = el.querySelector('.projectList__column_1');
    const column2Container = el.querySelector('.projectList__column_2');
    const column3Container = el.querySelector('.projectList__column_3');
    const mobileFilter = el.querySelectorAll('.projectList__filter_mobile');

    const initProjectsList = projects;
    let currentProjectsList = [];
    // console.log('initProjectsList', initProjectsList);

    column1Container.innerHTML = '';
    column2Container.innerHTML = '';
    column3Container.innerHTML = '';

    const addProjects = (projectList) => {
      filter.style.zIndex = '-1';
      [...mobileFilter].forEach((filterBlock) => {
        filterBlock?.classList.toggle('loading');
      });
      el.classList.add('blockCursor');
      column1Container.innerHTML = '';
      column2Container.innerHTML = '';
      column3Container.innerHTML = '';
      // for mobile view we need only 1 column
      if (window.innerWidth < 768) {
        for (let i = 0; i < projectList.length; i += 1) {
          setTimeout(() => {
            column1Container.appendChild(projectList[i]);
            projectList[i].classList.add('active');
            const projectVideo = projectList[i].querySelector('video');
            if (projectVideo) {
              projectVideo.play();
              projectVideo.playsInline = true;
            }
          }, 100 * i);
        }
        setTimeout(() => {
          filter.style.zIndex = '0';
          el.classList.remove('blockCursor');
          [...mobileFilter].forEach((filterBlock) => {
            filterBlock?.classList.toggle('loading');
          });
        }, 50 * projectList.length);
        // for desktop view we need 3 column and we add each project from array to next column
      } else {
        for (let i = 0; i < projectList.length; i += 1) {
          if (i % 3 === 0) {
            setTimeout(() => {
              column1Container.appendChild(projectList[i]);
              projectList[i].classList.add('active');
              const projectVideo = projectList[i].querySelector('video');
              if (projectVideo) {
                console.log('projectVideo', projectVideo);
                projectVideo.play();
              }
            }, 100 * i);
          }
          if (i % 3 === 1) {
            setTimeout(() => {
              column2Container.appendChild(projectList[i]);
              projectList[i].classList.add('active');
              const projectVideo = projectList[i].querySelector('video');
              if (projectVideo) {
                // console.log('projectVideo', projectVideo);
                projectVideo.play();
              }
            }, 100 * i);
          }
          if (i % 3 === 2) {
            setTimeout(() => {
              column3Container.appendChild(projectList[i]);
              projectList[i].classList.add('active');
              const projectVideo = projectList[i].querySelector('video');
              if (projectVideo) {
                // console.log('projectVideo', projectVideo);
                projectVideo.play();
              }
            }, 100 * i);
          }
          window.scrollTo(0, 0);
          setTimeout(() => {
            el.classList.remove('blockCursor');
            filter.style.zIndex = '0';
          }, 100 * projectList.length);
        }
      }
    };

    addProjects(initProjectsList);

    // eslint-disable-next-line no-restricted-globals
    screen.orientation.addEventListener('change', () => addProjects(initProjectsList));

    const isImgHide = (project) => {
      const newValue = window.pageYOffset + window.innerHeight - 60;
      const eleValue = project.offsetTop;

      // Subtract the two and conclude
      if (newValue < eleValue) {
        // above element
        project.classList.add('projectList__image__anim');
      } else if (newValue > eleValue) {
        // below element
        project.classList.remove('projectList__image__anim');
      }
    };

    filterBtns.forEach((btn) => {
      btn.addEventListener('click', () => {
        filterBtns.forEach((item) => item.classList.remove('active'));
        currentProjectsList = [];
        const modifyedProjectsArray = projects;
        modifyedProjectsArray.forEach((project) => {
          project.classList.remove('projectList__image__anim');
          if (
            project
              .getAttribute('data-name')
              .includes(btn.getAttribute('data-name'))
          ) {
            currentProjectsList.push(project);
            btn.classList.add('active');
          } else if (btn.getAttribute('data-name') === 'all') {
            currentProjectsList.push(project);
            btn.classList.add('active');
          } else {
            project.classList.remove('active');
          }
        });

        addProjects(currentProjectsList);
      });
    });

    projects.forEach((img) => {
      window.addEventListener('scroll', () => {
        isImgHide(img);
      });
    });
  }
}

ProjectList.className = 'ProjectList';
export default ProjectList;
