import Animation from './01-atoms/animation/_animation';
import ScrollAnim from './01-atoms/animation/_scrollAnim';
import Button from './01-atoms/button/button';
import Attachment from './01-atoms/inputs/attachment';
import Range from './01-atoms/inputs/range';
import Select from './01-atoms/inputs/select';
import Share from './02-molecules/share/share';
import Accordion from './02-molecules/accordion/accordion';
import Alerts from './02-molecules/alerts/alerts';
import Popup from './02-molecules/popup/popup';
import Slider from './02-molecules/slider/slider';
import Teasers from './02-molecules/threeTeaser/teasers';
import Filter from './02-molecules/filter/filter';
import Dialogues from './02-molecules/dialogues/dialogues';
import Team from './02-molecules/team/team';
import Map from './02-molecules/contactForm/map';
import Horizontal from './02-molecules/hero/horizontal';
import Quote from './02-molecules/qoute/quote';
import Project from './02-molecules/project/project';
import Kurzportrait from './02-molecules/kurzportrait/kurzportrait';
import PageSlider from './02-molecules/pageSlider/pageSlider';
import Teaser from './02-molecules/teaser/teaser';
import ProjectList from './02-molecules/projectList/projectList';
import Hero from './02-molecules/hero/hero';
import Header from './03-organisms/header/header';

require('./main.scss');

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const html = document.querySelector('html');
  const Component = window.Streusel || {};

  html.classList.remove('no-js');

  Component.Animation = Animation.init();
  Component.ScrollAnim = ScrollAnim.init();
  Component.Button = Button.init();
  Component.Range = Range.init();
  Component.Dialogues = Dialogues.init();
  Component.Share = Share.init();
  Component.Accordion = Accordion.init();
  Component.Alerts = Alerts.init();
  Component.Popup = Popup.init();
  Component.Slider = Slider.init();
  Component.Attachment = Attachment.init();
  Component.Select = Select.init();
  Component.Header = Header.init();
  Component.Teasers = Teasers.init();
  Component.Filter = Filter.init();
  Component.Team = Team.init();
  Component.Map = Map.init();
  Component.Horizontal = Horizontal.init();
  Component.Quote = Quote.init();
  Component.Project = Project.init();
  Component.PageSlider = PageSlider.init();
  Component.ProjectList = ProjectList.init();
  Component.Hero = Hero.init();
  Component.Teaser = Teaser.init();
  Component.Kurzportrait = Kurzportrait.init();
});

const isIPadComponents = document.querySelectorAll('.isIPad');

const isIpadOS = () => navigator.maxTouchPoints
  && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
// console.log('isIpadOS', isIpadOS());
if (isIpadOS()) {
  // console.log('isIpadOS111', isIpadOS());
  isIPadComponents.forEach((block) => {
    block.classList.add('mobile__view');
  });
}
