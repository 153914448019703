import Base from '../../_config/base';

class Hero extends Base {
  constructor(el) {
    super(el);
    this.animationBlock = el.querySelector('.home__animation');
    this.logo = document.querySelector('.header__logo');
    const isIPadComponents = document.querySelectorAll('.isIPad');

    const isIpadOS = () => navigator.maxTouchPoints
      && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);

    if (isIpadOS()) {
      // console.log('isIpadOSHero', isIpadOS());
      isIPadComponents.forEach((block) => {
        block.classList.add('mobile__view');
      });
    }

    // const currentTime = new Date();

    // if (localStorage.getItem('axessTimer')) {
    //   const lastTime = localStorage.getItem('axessTimer');
    //   const time = currentTime.getTime() - lastTime;

    //   // const twoWeeks = 14 * 24 * 60 * 60 * 1000;
    //   const minute = 60 * 1000;

    //   if (time > minute) {
    //     this.animationBlock.classList.add('home__animation_active');
    //     this.logo.classList.add('animate');
    //     localStorage.setItem('axessTimer', currentTime.getTime());
    //   } else {
    //     this.animationBlock.classList.remove('home__animation_active');
    //     this.logo.classList.remove('animate');
    //   }
    // }
  }
}

Hero.className = 'hero';
export default Hero;
