import Base from '../../_config/base';

class Header extends Base {
  constructor(el) {
    super(el);

    this.hamburger = el.querySelector('#hamburger-icon');
    this.parentItem = el.querySelector('.has-children > a');
    this.hamburgerMenu = el.querySelector('.hamburger-menu');
    this.hamburgerSubMenu = el.querySelector('.hamburger-menu__submenu');
    this.backBtn = el.querySelector('.hamburger-menu__item--back');
    this.body = document.getElementById('body');
    this.logoBg = el.querySelector('#headerLogo');
    this.langBtns = el.querySelectorAll('.menu__footer_btn');

    window.onload = () => { this.logoBg.style.display = 'block'; };

    this.hamburger.onclick = (e) => {
      e.preventDefault();
      this.hamburger.classList.toggle('active');
      this.body.classList.toggle('overflow-hidden');
      el.classList.toggle('active');
      this.hamburgerMenu.classList.remove('active');

      if (this.hamburgerSubMenu.classList.contains('active')) {
        this.hamburgerSubMenu.classList.remove('active');
      }
    };

    this.parentItem.onclick = (e) => {
      e.preventDefault();
      this.hamburgerSubMenu.classList.toggle('active');
    };

    this.backBtn.onclick = (e) => {
      e.preventDefault();
      this.hamburgerSubMenu.classList.remove('active');
    };

    this.langBtns.forEach((langBtn) => {
      langBtn.onclick = () => {
        this.langBtns.forEach((btn) => btn.classList.toggle('active'));
      };
    });
  }
}

Header.className = 'Header';
export default Header;
