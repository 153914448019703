import Swiper, { Navigation, Pagination } from 'swiper';
import Base from '../../_config/base';

class Slider extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');

    const linkTrigger = document.querySelectorAll('.popup-open');
    const closePopup = document.querySelector('.popup-close');
    const closePopupBtn = document.querySelector('.popup-close-btn');
    const popup = document.querySelector('.popup');

    const sliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      centeredSlides: true,
      initialSlide: 0,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);

    linkTrigger.forEach((trigger) => {
      trigger.onclick = () => {
        swiper.slideTo(trigger.getAttribute('data-index'), 0, false);
        popup.classList.add('popup-show');
      };
    });
    closePopup.onclick = () => {
      popup.classList.remove('popup-show');
      swiper.slideTo(0, 0, false);
    };
    closePopupBtn.onclick = () => {
      popup.classList.remove('popup-show');
      swiper.slideTo(0, 0, false);
    };
  }
}

Slider.className = 'Slider';
export default Slider;
