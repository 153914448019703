import Base from '../../_config/base';

class Team extends Base {
  constructor(el) {
    super(el);

    this.memberBlocks = el.querySelectorAll('.team__inner');
    this.triggerBtns = el.querySelectorAll('.team__list_item');
    this.memberListBlock = el.querySelector('.team__list');
    this.arrow = el.querySelector('.project__arrow.arrow-list');
    this.arrowsCV = el.querySelectorAll('.project__arrow.arrow-cv');
    this.teamContentBlocks = el.querySelectorAll('.team__content');
    this.activeBlock = null;
    this.activeBtn = null;

    if (this.memberListBlock.scrollHeight > window.innerHeight - 80) {
      this.arrow.classList.add('right-position');
      this.arrow.classList.add('visible');
    }

    if (this.memberListBlock.scrollHeight > window.innerHeight - 80) {
      this.arrow.classList.add('right-position');
      this.arrow.classList.add('visible');
    }

    const scrollListener = (block, arrow) => {
      block.addEventListener('scroll', () => {
        // hide arrow during scroll
        arrow.classList.remove('visible');
        if (block.scrollHeight - window.innerHeight + 60 - block.scrollTop < 0) {
          arrow.classList.add('rotate');
        } else {
          arrow.classList.remove('rotate');
        }
      });

      block.addEventListener('scrollend', () => {
        // show arrow after end of scroll
        arrow.classList.add('visible');
      });
    };

    scrollListener(this.memberListBlock, this.arrow);

    this.triggerBtns.forEach((btn) => {
      btn.onclick = () => {
        this.triggerBtns.forEach((btnItem) => btnItem.classList.remove('active'));

        this.memberBlocks.forEach((memberBlock) => {
          if (memberBlock.getAttribute('data-name') === btn.getAttribute('data-name')) {
            memberBlock.classList.add('active');
            btn.classList.add('active');
            this.activeBlock = memberBlock;
            this.activeBtn = btn;

            const teamContent = memberBlock.querySelector('.team__content');
            const arrow = teamContent.querySelector('.project__arrow.arrow-cv');
            if (teamContent.scrollHeight > window.innerHeight - 80) {
              arrow.classList.add('visible');
            }
            scrollListener(teamContent, arrow);
          } else {
            memberBlock.classList.remove('active');
          }
        });
      };

      if (window.innerWidth > 1023) {
        btn.onmouseover = () => {
          this.memberBlocks.forEach((memberBlock) => {
            if (memberBlock.getAttribute('data-name') === btn.getAttribute('data-name')) {
              memberBlock.classList.add('active');
              const teamContent = memberBlock.querySelector('.team__content');
              const arrow = teamContent.querySelector('.project__arrow.arrow-cv');
              if (teamContent.scrollHeight > window.innerHeight - 80) {
                arrow.classList.add('visible');
              }
              scrollListener(teamContent, arrow);
              btn.classList.add('active');
            } else {
              memberBlock.classList.remove('active');
            }
          });
        };

        btn.onmouseleave = () => {
          this.triggerBtns.forEach((btnItem) => btnItem.classList.remove('active'));
          this.activeBtn.classList.add('active');

          this.memberBlocks.forEach((memberBlock) => memberBlock.classList.remove('active'));
          this.activeBlock.classList.add('active');
        };
      }
    });
  }
}

Team.className = 'Team';
export default Team;
