/* eslint-disable no-nested-ternary */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from 'gsap/Draggable';
import Base from '../../_config/base';

class Animation extends Base {
  constructor(el) {
    super(el);

    gsap.registerPlugin(ScrollTrigger, Draggable);
    ScrollTrigger.refresh();

    // Page heading animation
    const pageTitles = gsap.utils.toArray('.page__heading');
    const sliderContentBlocks = gsap.utils.toArray('.project__content_wrapper');
    const sliderContentDescriprion = gsap.utils.toArray('.project__content_description');
    const sliderMobilePageImages = gsap.utils.toArray('.image__wrapper_mobile');

    sliderMobilePageImages.forEach((image) => {
      setTimeout(() => {
        image.classList.add('visible');
      }, 0.5);
    });

    pageTitles.forEach((title) => {
      gsap.fromTo(
        title,
        { y: 200, opacity: 0.5 },
        { y: 0, opacity: 1, duration: 0.8 },
      );
    });

    sliderContentBlocks.forEach((title) => {
      gsap.fromTo(
        title,
        { y: 200, opacity: 0.5 },
        { y: 0, opacity: 1, duration: 0.8 },
      );
    });

    sliderContentDescriprion.forEach((title) => {
      gsap.fromTo(
        title,
        { y: 200, opacity: 0.5 },
        { y: 0, opacity: 1, duration: 0.8 },
      );
    });

    // pageHeaderText.forEach((text) => {
    //   gsap.fromTo(
    //     text,
    //     { opacity: 0 },
    //     { opacity: 1, duration: 0.4, delay: 0.3 },
    //   );
    // });

    // Section heading animation
    // Define fontSize for different screen sizes:
    // const sectionTitlefont = {
    //   SM: 24,
    //   MD: 36,
    //   XL: 48,
    // };

    // const h2font = window.innerWidth > 1920
    //   ? sectionTitlefont.XL : window.innerWidth > 768
    //     ? sectionTitlefont.MD : sectionTitlefont.SM;

    // sectionTitles.forEach((sectionTitle) => {
    //   gsap.to(sectionTitle, {
    //     scrollTrigger: {
    //       trigger: sectionTitle,
    //       start: 'top 70%',
    //       end: 'top center',
    //       // markers: true,
    //       scrub: true,
    //       ease: 'none',
    //     },
    //     fontSize: h2font * 1.6,
    //   });
    // });

    // Quotes animation
    // const quoteWrappers = gsap.utils.toArray('.quote__wrapper');

    // quoteWrappers.forEach((quote) => {
    //   gsap.set(
    //     quote,
    //     { x: 300 },
    //   );

    //   gsap.to(quote, {
    //     scrollTrigger: {
    //       trigger: quote,
    //       start: 'top bottom',
    //       end: 'top center',
    //       // markers: true,
    //       scrub: true,
    //       ease: 'none',
    //     },
    //     x: 0,
    //   });
    // });
  }
}

Animation.className = 'Animation';
export default Animation;
