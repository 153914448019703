/* eslint-disable no-nested-ternary */
import Base from '../../_config/base';

class ScrollAnim extends Base {
  constructor(el) {
    super(el);

    window.addEventListener('load', () => {
      // add animation to blocks which are visible afeter window loading
      // Blocks should have initially class 'scrollAnim'
      el.classList.remove('scrollAnim');
    });

    const isBlockHide = (block) => {
      const newValue = window.pageYOffset + window.innerHeight - 60;
      const eleValue = block.offsetTop;
      // console.log('newValue', newValue);
      // console.log('eleValue', eleValue);
      // Subtract the two and conclude
      if (newValue < eleValue) {
        // above element
        block.classList.add('scrollAnim');
      } else if (newValue > eleValue) {
        // below element
        block.classList.remove('scrollAnim');
      }
    };

    window.addEventListener('scroll', () => {
      // console.log('el', el);
      isBlockHide(el);
    });
  }
}

ScrollAnim.className = 'ScrollAnim';
export default ScrollAnim;
