import { toggleClass } from '../../_config/utils';
import Base from '../../_config/base';

class Popup extends Base {
  constructor(el) {
    super(el);

    const linkTrigger = document.querySelectorAll('.popup-open');
    const closePopup = el.querySelector('.popup-close');
    const closePopupBtn = el.querySelector('.popup-close-btn');

    const popup = el;

    linkTrigger.forEach((trigger) => {
      trigger.onclick = () => toggleClass(popup, 'popup-show');
    });
    closePopup.onclick = () => toggleClass(popup, 'popup-show');
    closePopupBtn.onclick = () => toggleClass(popup, 'popup-show');
  }
}

Popup.className = 'Popup';
export default Popup;
