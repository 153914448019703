// eslint-disable-next-line import/no-extraneous-dependencies
import { Loader } from 'google-maps';
import Base from '../../_config/base';

class Map extends Base {
  constructor(el) {
    super(el);

    const loader = new Loader('AIzaSyBBLJqBeIq9WVb_3kFgs5Ej9f1TA5I6GqA');

    // eslint-disable-next-line
    loader.load().then(function (google) {
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 13,
        center: {
          lat: 47.18397716356803,
          lng: 8.522744966175173,
        },
        // <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d632.5185751031585!2d8.522744966175173!3d47.18397716356803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aaaf80198403b%3A0xeaa07501d426983b!2sAxess%20Architekten%20AG!5e0!3m2!1sru!2sch!4v1700214614899!5m2!1sru!2sch" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        styles: [
          {
            featureType: 'all',
            elementType: 'all',
            stylers: [{
              saturation: '-100',
            },
            {
              lightness: '0',
            },
            {
              gamma: '0.65',
            },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels',
            stylers: [{
              visibility: 'on',
            }],
          },
          {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [{
              visibility: 'on',
            }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{
              visibility: 'off',
            }],
          },
          {
            featureType: 'transit.station',
            elementType: 'all',
            stylers: [{
              visibility: 'off',
            }],
          },
        ],
      });

      const infoWindow = new google.maps.InfoWindow();
      const locations = [
        ['Axess Architekrten, Industriestrasse 70, Zug', 47.18397716356803, 8.522744966175173, 'all'],
      ];

      // function to create the markers on the map and set the category to each marker
      function setMarkers(gmap) {
        const points = el.querySelectorAll('.point');

        for (let i = 0; i < locations.length; i += 1) {
          const location = locations[i];
          // eslint-disable-next-line
          const marker = new google.maps.Marker({
            position: {
              lat: location[1],
              lng: location[2],
            },
            map: gmap,
            icon: new google.maps.MarkerImage(
              '/assets/images/pin.svg',
              null,
              new google.maps.Point(0, 0),
            ),
            // shape,
            visible: true,
            category: location[3],
            title: `${location[0]}`,
          });

          // give each marker a category and show only the ones that are selected in the filter
          [...points].forEach((point) => {
            marker.category === 'all' ? marker.setVisible() : marker.setVisible(true);
            // point.onmouseover = () => point.querySelector('.tooltip').classList.remove('d-none');
            // point.onmouseleave = () => point.querySelector('.tooltip').classList.add('d-none');
            point.addEventListener('mouseover', (e) => {
              const category = e.target.parentNode.getAttribute('data-category');
              infoWindow.close();
              if (marker.category === category || marker.category === 'all') {
                marker.setVisible(true);
              } else {
                marker.setVisible(false);
              }
            });
          });

          // add info window to each marker
          marker.addListener('click', () => {
            // infoWindow.close();
            // infoWindow.setContent(marker.getTitle());
            // infoWindow.open(marker.getMap(), marker);
            window.open(`https://www.google.com/maps/search/?api=1&query=${location[0]}`);
          });
        }
      }
      setMarkers(map);
    });
  }
}

Map.className = 'Map';
export default Map;
