import Base from '../../_config/base';

class Teaser extends Base {
  constructor(el) {
    super(el);
    const teasers = el.querySelectorAll('.teaser__inner');

    const isImgHide = (teaser) => {
      const newValue = window.pageYOffset + window.innerHeight - 160;
      const eleValue = teaser.offsetTop;

      // Subtract the two and conclude
      if (newValue < eleValue) {
        // above element
        teaser.classList.add('teaser__inner__anim');
      } else if (newValue > eleValue) {
        // below element
        teaser.classList.remove('teaser__inner__anim');
      }
    };

    teasers.forEach((teaser) => {
      window.addEventListener('scroll', () => {
        isImgHide(teaser);
      });
    });
  }
}

Teaser.className = 'Teaser';
export default Teaser;
