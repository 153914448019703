import Swiper, { Navigation, Pagination } from 'swiper';
import Base from '../../_config/base';

class Project extends Base {
  constructor(el) {
    super(el);
    const projectSlider = el.querySelector('.swiper__project');
    // const projectContent = el.querySelector('.project__content');
    const imageSections = el.querySelectorAll('.image__wrapper_desctop');
    const arrows = el.querySelectorAll('.project__arrow');

    const projectSliderOpt = {
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      centeredSlides: true,
      direction: 'vertical',
      slidesPerView: 1,
      updateOnWindowResize: true,
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(projectSlider, projectSliderOpt);

    imageSections.forEach((block, index) => {
      // Show arrow if blocks height more than window height minus header height
      const currentArrow = arrows[index];
      if (arrows[index] && block.scrollHeight > window.innerHeight - 80) {
        arrows[index].classList.add('visible');
      }

      block.addEventListener('mouseenter', () => {
        // disable swiper when mouseover on image section
        swiper.enabled = false;

        if (arrows[index] && block.scrollHeight > window.innerHeight - 80) {
          arrows[index].classList.add('visible');
        }

        block.addEventListener('scroll', () => {
          // hide arrow during scroll
          currentArrow.classList.remove('visible');
          // detect if scroll reach bottom of section
          if (Math.abs((block.scrollHeight - block.scrollTop - block.clientHeight) < 1)) {
            currentArrow.classList.add('rotate');
          } else if (block.scrollTop === 0) { // scroll reach top of section
            currentArrow.classList.remove('rotate');
          }
        });

        block.addEventListener('scrollend', () => {
          // show arrow after end of scroll
          currentArrow.classList.add('visible');
        });
      });

      block.addEventListener('mouseleave', () => { swiper.enabled = true; });
    });
  }
}

Project.className = 'Project';
export default Project;
