import Base from '../../_config/base';

class Kurzportrait extends Base {
  constructor(el) {
    super(el);

    const contentSection = el.querySelector('.kurzportrait__content_wrapper-desctop');
    const arrow = el.querySelector('.content-arrow');

    // console.log('contentSection.scrollHeight', contentSection.scrollHeight);

    if (arrow && contentSection.scrollHeight > window.innerHeight - 80) {
      arrow.classList.add('visible');
    }

    contentSection.addEventListener('scroll', () => {
      // hide arrow during scroll
      arrow.classList.remove('visible');
      // detect if scroll reach bottom of section
      if (Math.abs((contentSection.scrollHeight
        - contentSection.scrollTop - contentSection.clientHeight) < 1)) {
        arrow.classList.add('rotate');
      } else if (contentSection.scrollTop === 0) { // scroll reach top of section
        arrow.classList.remove('rotate');
      }
    });

    contentSection.addEventListener('scrollend', () => {
      // show arrow after end of scroll
      arrow.classList.add('visible');
    });
  }
}

Kurzportrait.className = 'Kurzportrait';
export default Kurzportrait;
