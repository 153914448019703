import Base from '../../_config/base';

class Quote extends Base {
  constructor(el) {
    super(el);
    this.config = {
      time: 30,
      show: false,
    };
    const quotes = el.querySelectorAll('.quote-text');

    quotes.forEach((quote) => {
      const text = quote.textContent;
      window.addEventListener('load', () => this.turnOnIncreasing(quote, text));
      window.addEventListener('scroll', () => this.turnOnIncreasing(quote, text));
    });
  }

  turnOnIncreasing(el, text) {
    if (!this.config.show && this.isInViewport(el)) {
      this.config.show = true;
      const quoteHeight = el.offsetHeight;
      el.style.height = `${quoteHeight}px`;
      let text1 = '';
      for (let i = 0; i < text.length; i += 1) {
        // eslint-disable-next-line no-loop-func
        setTimeout(() => {
          text1 += text[i];
          el.textContent = text1;
        }, 20 * i);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isInViewport(elem) {
    const distance = elem.getBoundingClientRect();
    return distance.top < window.innerHeight;
  }
}

Quote.className = 'Quote';
export default Quote;
