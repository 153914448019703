// eslint-disable-next-line object-curly-newline
import Swiper, { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import Base from '../../_config/base';

class Horizontal extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper-h');

    const sliderOpt = {
      // configure Swiper to use modules
      speed: 600,
      mousewheel: {
        releaseOnEdges: true,
        enabled: true,
        sensitivity: 1.8,
      },
      // freeMode: true,
      // parallax: true,
      modules: [Navigation, Pagination, Mousewheel, FreeMode],
      centeredSlides: true,
      slidesPerView: 1,
      direction: 'horizontal',
      pagination: {
        el: '.swiper__project_pagination',
        clickable: true,
      },
      // Navigation arrows
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);
  }
}

Horizontal.className = 'Horizontal';
export default Horizontal;
